import React from "react"
import { Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CountDownTimer from "../components/countdowner"
import logo from "../images/VeriottLogo.png"
import triangle from "../images/Triangle.jpg"
import rectangle from "../images/rectangle.jpg"

const MPage = () => (
  <Layout>
    <SEO title="Join us!" description="Join us and redefine the concept of perfume" />
    <div className="intro">
      <img className="veriottLogo" src={logo} alt="veriott logo"></img>
    </div>

    <div>
    <h2 style={{marginBottom:"0.5em",}}>Hi friend!</h2>
      <p style={{fontWeight: 400,}}>We are veriott, a newly founded startup brand from Stockholm, Sweden 🇸🇪</p>
      <p>With a mission to <b>redefine</b> the concept of <b>perfume</b> – we strive to create a product that is stylish, environmentally sustainable, and straight up awesome.
      </p>
      <div style={{display:"flex",justifyContent:"space-between",}}>
        <div style={{paddingRight:"1.0875rem",}} ><img  className="" src={triangle} alt="veriott logo"></img></div>
        <div><img className="" src={rectangle} alt="veriott logo"></img></div>
      </div>
      <h3><i>“We are looking for a small set of core partners, influencers that wish to join us on a journey of a lifetime.”</i></h3>
    
      <p >The company behind veriott runs <b>multiple other webshops</b> in many different industries. In the year 2020 alone, we have delivered orders to 10.000+ customers. Therefore, <b>future collaborations</b> with our other brands will also be available to you.</p>
    
      <h2 style={{marginTop:"2em",marginBottom:"0.3em",}}>Our offer to you:</h2>
      <p>Get our founders product sent to you right away, <b>free of charge</b> and <b><u>promote it through your social channels</u></b>, and perhaps to your friends and family.</p>

    </div>
  </Layout>
)

export default MPage
